.App {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;
}

.kitty {
  width: 96px;
}

.tag {
  margin-top: 16px;
  font-size: 12px;
  text-align: center;
  color: slategray;
  font-style: italic;
}

a {
  text-decoration: none;
}
